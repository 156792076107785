import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FuncionalidadesModuloService {
    constructor(http) {
        this.http = http;
    }
    /**
     * Permite obtener todas las funcionalidades agrupadads por coleccione y las colecciones agrupadas por modulo
     */
    getAllByScheme() {
        return this.http.get(environment.apiUrl.admin + "/functionalities-scheme");
    }
    getById(id) {
        return this.http.get(`${environment.apiUrl.admin}/modules-functionalities/${id}`);
    }
    getAll() {
        return this.http.get(environment.apiUrl.admin + "/modules-functionalities");
    }
    getByModule(module_id) {
        return this.http.get(`${environment.apiUrl.admin}/modules/${module_id}/modules-functionalities `);
    }
    save(form_data) {
        console.log(form_data._id === null);
        if (form_data._id === null) {
            return this.add(form_data);
        }
        return this.edit(form_data);
    }
    add(form_data) {
        delete form_data._id;
        return this.http.post(`${environment.apiUrl.admin}/modules-functionalities`, form_data);
    }
    edit(form_data) {
        return this.http.put(`${environment.apiUrl.admin}/modules-functionalities/${form_data._id}`, form_data);
    }
    delete(_id) {
        return this.http.delete(`${environment.apiUrl.admin}/modules-functionalities/${_id}`);
    }
}
FuncionalidadesModuloService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FuncionalidadesModuloService_Factory() { return new FuncionalidadesModuloService(i0.ɵɵinject(i1.HttpClient)); }, token: FuncionalidadesModuloService, providedIn: "root" });
