import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Usuario } from 'src/app/entities/usuario';
import Swal from 'sweetalert2';
export class LoginComponent {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
        this.formData = new Usuario();
    }
    ngOnInit() {
        this.authService.closeSession();
    }
    login() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            jQuery.fancybox.showLoading();
            // consultamos si existe algun registro de usuario con los parametros recibidos
            const userData = yield this.authService.login(this.formData);
            // verificamos si la consulta devolvio algun registro
            if (userData == null) {
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: 'Usuario o contraseña incorrecta!'
                });
            }
            else {
                this.router.navigate(['/cuentas']);
            }
            jQuery.fancybox.hideLoading();
        });
    }
}
