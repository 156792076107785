import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ImplementacionEmpresaService {
    constructor(http) {
        this.http = http;
    }
    getAll() {
        return this.http.get(environment.apiUrl.admin + "/implementations");
    }
    save(estado) {
        if (!estado._id) {
            return this.add(estado);
        }
        return this.edit(estado);
    }
    add(estado) {
        delete estado._id;
        return this.http.post(environment.apiUrl.admin + "/implementations", estado);
    }
    edit(estado) {
        return this.http.put(environment.apiUrl.admin + "/implementations/" + estado._id, estado);
    }
    get(_id) {
        return this.http.get(environment.apiUrl.admin + "/implementations/" + _id);
    }
    getByBusiness(_id) {
        return this.http.get(environment.apiUrl.admin + "/business/" + _id + "/implementations");
    }
}
ImplementacionEmpresaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImplementacionEmpresaService_Factory() { return new ImplementacionEmpresaService(i0.ɵɵinject(i1.HttpClient)); }, token: ImplementacionEmpresaService, providedIn: "root" });
