import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FuncionalidadesPlanService {
    constructor(http) {
        this.http = http;
    }
    /**
     * Permite obtener un registro relacionado con el id
     * @param id
     */
    getByPlanId(id) {
        return this.http.get(environment.apiUrl.admin + "/plans/" + id + "/functionalities");
    }
    /**
     * Permite agregar un modulo
     * @param formData
     */
    setByPlanId(id, formData) {
        return this.http.post(environment.apiUrl.admin + "/plans/" + id + "/functionalities", formData);
    }
}
FuncionalidadesPlanService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FuncionalidadesPlanService_Factory() { return new FuncionalidadesPlanService(i0.ɵɵinject(i1.HttpClient)); }, token: FuncionalidadesPlanService, providedIn: "root" });
