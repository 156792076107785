import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Usuario } from '../entities/usuario';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthService {
    constructor(http) {
        this.http = http;
        this.userLogged = null;
        this.token = null;
        // creamos los disparadores de Eventos
        this.loginEvent = new EventEmitter();
        this.logoutEvent = new EventEmitter();
        this.load();
        this.updateBodyElement();
    }
    /**
     * Permite cargar la informacion del usuario logueado
     */
    load() {
        this.token = localStorage.getItem('token');
        const userLogged = localStorage.getItem('userLogged');
        if (userLogged !== null) {
            this.userLogged = JSON.parse(userLogged);
        }
    }
    /**
     * Permite guardar la informacion del usuario logueado
     */
    save() {
        if (this.token !== null) {
            localStorage.setItem('token', this.token);
        }
        if (this.userLogged !== null) {
            localStorage.setItem('userLogged', JSON.stringify(this.userLogged));
        }
    }
    /**
     * Permite borrar la informacion almacenada localmente
     */
    remove() {
        localStorage.removeItem('token');
        localStorage.removeItem('userLogged');
    }
    /**
     * Permite enviar al servidor las credenciales de Acceso
     * para que un usuario pueda iniciar sesión
     *
     * @param authForm
     */
    login(authForm) {
        return new Promise((resolve) => {
            this.http.post(environment.apiUrl.admin + '/login', authForm)
                .subscribe((response) => {
                this.token = response['token'];
                this.userLogged = new Usuario();
                this.userLogged._id = response['user']._id;
                this.userLogged.username = response['user'].username;
                this.userLogged.permisos = response['user'].permisos;
                this.userLogged.password = response['user'].password;
                // guardamos la información por si el ususario refresca la pestaña del navegador (recargar pagina)
                this.save();
                resolve(this.userLogged);
                this.loginEvent.emit(this.userLogged);
                this.updateBodyElement();
                setTimeout(() => {
                    // disparamos nuevamente el evento para que se recargue el codigo (js) de la plantilla
                    jQuery(window).trigger('load');
                }, 1000);
            }, (responseError) => {
                console.info("responseError: ", responseError);
                resolve(null);
            });
        });
    }
    /**
     * Permite cerrar sesion
     */
    closeSession() {
        this.userLogged = null;
        this.token = null;
        this.remove();
        this.updateBodyElement();
        this.logoutEvent.emit();
    }
    /**
     * Permite que el usuario salga de la sesion abierta
     */
    logout() {
        this.http.post(environment.apiUrl.root + '/logout', null)
            .subscribe(() => {
            this.closeSession();
        });
    }
    /**
     * Permite actualizar el elemento ya que este maneja unos atributos y clases que vienen establecidos en la plantilla
     */
    updateBodyElement() {
        // verificamos si el usuario esta logueado
        if (this.userLogged === null) {
            jQuery('body').addClass('1-columns');
            jQuery('body').removeClass('2-columns');
            jQuery('body').removeClass('fixed-navbar');
            jQuery('body').removeClass('menu-expanded');
            jQuery('body').attr('data-col', '1-columns');
        }
        else {
            jQuery('body').addClass('2-columns');
            jQuery('body').addClass('fixed-navbar');
            jQuery('body').addClass('menu-expanded');
            jQuery('body').removeClass('1-columns');
            jQuery('body').attr('data-col', '2-columns');
        }
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
