import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CuentasAdministradoresService {
    constructor(http) {
        this.http = http;
    }
    /**
     * Permite obtener todos los registros
     */
    getAll() {
        return this.http.get(environment.apiUrl.admin + '/accounts-admin');
    }
    /**
     * Permite obtener un registro
     * @param _id
     */
    get(_id) {
        return this.http.get(environment.apiUrl.admin + '/accounts-admin/' + _id);
    }
    /**
     * Permite guardar los datos de un registro
     * @param formData
     */
    save(formData) {
        // si _id está null, es por que es un registro nuevo
        if (formData._id === null) {
            // como vamos a hacer un registro nuevo, eliminamos el campo _id del formulario
            delete formData._id;
            return this.add(formData);
        }
        return this.edit(formData);
    }
    /**
     * Permite registrar los datos de un nuevo usuario
     * @param formData
     */
    add(formData) {
        return this.http.post(environment.apiUrl.admin + '/accounts-admin', formData);
    }
    /**
     * Permite guardar los cambios de un registro
     * @param formData
     */
    edit(formData) {
        return this.http.put(environment.apiUrl.admin + '/accounts-admin/' + formData._id, formData);
    }
    /**
     * Permite eliminar un plan
     * @param _id
     */
    remove(_id) {
        return this.http.delete(environment.apiUrl.admin + '/accounts-admin/' + _id);
    }
    /**
     * Permite actualizar la contraseña de una cuenta
     */
    updatedPassword(formData) {
        return this.http.put(environment.apiUrl.admin + '/accounts-admin/' + formData._id + '/update-password', formData);
    }
}
CuentasAdministradoresService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CuentasAdministradoresService_Factory() { return new CuentasAdministradoresService(i0.ɵɵinject(i1.HttpClient)); }, token: CuentasAdministradoresService, providedIn: "root" });
