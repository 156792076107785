import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ModulosService {
    constructor(http) {
        this.http = http;
    }
    /**
     * Permite obtener todos los registros de modulos que hay en la base de datos
     */
    getAll() {
        return this.http.get(environment.apiUrl.admin + "/modules");
    }
    /**
     * Permite obtener un registro relacionado con el id
     * @param id
     */
    getById(id) {
        return this.http.get(environment.apiUrl.admin + "/modules/" + id);
    }
    /**
     * Permite guardar la entidad de un modulo
     * @param formData
     */
    save(formData) {
        return (formData._id === null) ? this.add(formData) : this.edit(formData);
    }
    /**
     * Permite agregar un modulo
     * @param formData
     */
    add(formData) {
        delete formData._id;
        return this.http.post(environment.apiUrl.admin + "/modules", formData);
    }
    /**
     * Permiter guardar los cambios de un modulo
     * @param formData
     */
    edit(formData) {
        return this.http.put(environment.apiUrl.admin + "/modules/" + formData._id, formData);
    }
    /**
     * Permite eliminar un modulo
     * @param _id
     */
    delete(_id) {
        return this.http.delete(environment.apiUrl.admin + "/modules/" + _id);
    }
}
ModulosService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModulosService_Factory() { return new ModulosService(i0.ɵɵinject(i1.HttpClient)); }, token: ModulosService, providedIn: "root" });
